import React, { useState, useCallback, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Link,
  Card,
  CardContent,
  Typography,
  Chip,
  Tooltip,
  Stack,
} from "@mui/material";
import IMDRFCategorize from "./categorize/IMDRFCategorize";
import PatientCardSkeleton from "./skeletons/PatientCardSkeleton";
import theme from "../../../styles/theme";
import StructuredDataExpandTable from "../../shared/StructuredDataExpandTable";
import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { CurrentPatient } from "../ProjectDetails";
import {
  getCategoryFromAttributeType,
  getCategoryFromAttributeTypeId,
} from "./categorize/CategorizeUtils";
import {
  ReportProblemOutlined,
  MedicalServicesOutlined,
  TrendingUpOutlined,
  DeviceHubOutlined,
  StickyNote2Outlined,
} from "@mui/icons-material";
import PromDataExpandTable from "./PromDataExpandTable";

const one_day = 1000 * 60 * 60 * 24;

export default function PatientCard({
  patient,
  patientLoading,
  updatePatientList,
}) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [isOpen, setIsOpen] = useState(false);
  const [attributeToEdit, setAttributeToEdit] = useState(null);
  const [openProblems, setOpenProblems] = useState(false);
  const [mark, setMark] = useState(null);
  const [adverseEventList, setAdverseEventList] = useState(null);
  const [performanceList, setPerformanceList] = useState(null);
  const [clinicalBenefitList, setClinicalBenefitList] = useState(null);
  const [deviceDeficientList, setDeviceDeficientList] = useState(null);

  const {
    isProblemsUpdated,
    setIsProblemsUpdated,
    patientProblems,
    setPatientProblems,
    projectSettings,
    isPromsUpdated,
    setIsPromsUpdated,
  } = useContext(CurrentPatient);

  const { isLoading, data: problemsData, fetch: fetchProblems } = useFetch();

  function handleNewPopOver(e, attribute) {
    setIsOpen(true);
    setAttributeToEdit(attribute);
    setMark(e.currentTarget);
  }

  function handlePopOverClose() {
    setIsOpen(false);
  }

  const successFail = (status) => {
    let isTrueSet = String(status).toLowerCase() === "true";
    if (isTrueSet) return "Success";
    return "Failure";
  };

  const yesNo = (status) => {
    let isTrueSet = String(status).toLowerCase() === "true";
    if (isTrueSet) return "Yes";
    return "No";
  };

  const handleProblemsDataLoad = useCallback(() => {
    setOpenProblems(!openProblems);
  }, [openProblems]);

  useEffect(() => {
    if (openProblems && !isProblemsUpdated) {
      fetchProblems(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/problems`
      ).then((_) => {
        setPatientProblems(problemsData);
        setIsProblemsUpdated(true);
      });
    }
  }, [
    fetchProblems,
    openProblems,
    patient.id,
    projectId,
    patientProblems,
    setPatientProblems,
    problemsData,
    isProblemsUpdated,
    setIsProblemsUpdated,
  ]);

  const [isPromEnabled, setIsPromEnabled] = useState(false);
  const [isPromListOpen, setIsPromListOpen] = useState(false);
  const {
    isLoading: isPromDataLoading,
    data: promData,
    fetch: fetchPromData,
    reset: resetPromData,
  } = useFetch();

  useEffect(() => {
    if (isPromListOpen && !isPromsUpdated && isPromEnabled) {
      fetchPromData(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/proms/`,
        removeUnscoreablePromQuestions,
        () => {
          setIsPromsUpdated(true);
        }
      );
    }
  }, [
    isPromListOpen,
    isPromsUpdated,
    isPromEnabled,
    projectId,
    patient.id,
    fetchPromData,
    setIsPromsUpdated,
  ]);

  useEffect(() => {
    if (projectSettings) {
      let setting = projectSettings.find((s) => s.name === "PROM_FORMS");
      let isEnabled = setting && setting.value && true;
      setIsPromEnabled(isEnabled);
    } else {
      setIsPromEnabled(false);
    }
  }, [projectSettings, setIsPromEnabled]);

  // Not all answers in a PROM response can be scored.  We don't show these questions in the UI.
  //  Example: "Is this the pre-operative, 3 month post-operative, or 1 year post-operative assessment?" is a question
  //  on the patients "MC OXFORD KNEE SCORE (OKS) RIGHT" form data, but does not contribute to the Form's score.
  const removeUnscoreablePromQuestions = function (promApiData) {
    for (let promModel of promApiData) {
      promModel.answers = promModel.answers.filter(
        (a) => a.question.questionOrder
      );
    }
    return promApiData;
  };

  const handlePromLoad = useCallback(() => {
    setIsPromListOpen(!isPromListOpen);
  }, [isPromListOpen]);

  // Reset prom states when the patient changes
  useEffect(() => {
    if (patientLoading) {
      setIsPromListOpen(false);
      resetPromData();
    }
  }, [patientLoading, setIsPromListOpen, resetPromData]);

  useEffect(() => {
    if (patient.attributes) {
      let category = getCategoryFromAttributeType("Adverse Event");
      const aeList = patient.attributes?.filter(
        (a) => a.attributeTypeId === category?.attributeTypeId
      );
      setAdverseEventList(aeList);

      category = getCategoryFromAttributeType("Performance");
      const perfList = patient.attributes?.filter(
        (a) => a.attributeTypeId === category?.attributeTypeId
      );
      setPerformanceList(perfList);

      category = getCategoryFromAttributeType("Clinical Benefit");
      const cbList = patient.attributes?.filter(
        (a) => a.attributeTypeId === category?.attributeTypeId
      );
      setClinicalBenefitList(cbList);

      category = getCategoryFromAttributeType("Device Deficiency");
      const ddList = patient.attributes?.filter(
        (a) => a.attributeTypeId === category?.attributeTypeId
      );
      setDeviceDeficientList(ddList);
    }
  }, [patient.attributes]);

  const problemDataFields = [
    { field: "problemName", headerName: "Problem", width: 250 },
    { field: "yearNoted", headerName: "Year Noted", width: 100 },
    { field: "diffDays", headerName: "Days Pre/Post", width: 115 },
    { field: "patientAgeAtEvent", headerName: "Age", width: 100 },
    { field: "problemStatus", headerName: "Status", width: 100 },
  ];

  function getAttributeLabel(attribute, suffix) {
    let label = "";
    let truncLength = 25;
    if (attribute.imdrfCode) {
      let imdrfCode = attribute.imdrfCode;
      label = imdrfCode.code + " - ";
      if (imdrfCode.term.length > truncLength) {
        label += imdrfCode.term.slice(0, truncLength) + "...";
      } else {
        label += imdrfCode.term;
      }
    } else {
      let category = getCategoryFromAttributeTypeId(attribute.attributeTypeId);
      if (
        category.name === "Performance" ||
        category.name === "Clinical Benefit"
      ) {
        label = successFail(attribute.attributeValue);
      } else if (category.name === "Device Deficiency") {
        label = yesNo(attribute.attributeValue);
      } else {
        label = attribute.attributeValue;
      }
    }
    label += " (" + suffix + ")";
    return label;
  }

  if (!patient?.id || patientLoading) {
    return <PatientCardSkeleton />;
  }

  return (
    <Card className={"patient-card"}>
      <CardContent align="left" sx={{ padding: 0 }}>
        <Stack className={"content"} flexDirection={"column"}>
          <Typography className={"title"} variant="subtitle1">
            Patient Info
          </Typography>
          <div className="column">
            <div className="row">
              <Typography variant="body2">Age</Typography>
              <Typography className={"bold-weight"} variant="body2">
                {patient.age}
              </Typography>
            </div>
            <div className="row">
              <Typography variant="body2">Gender</Typography>
              <Typography className={"bold-weight"} variant="body2">
                {patient.gender}
              </Typography>
            </div>
            {patient.deathFlag && (
              <div className="row">
                <Typography variant="body2">Deceased</Typography>
                {patient.deathFlag ? (
                  <Typography className={"bold-weight"} variant="body2">
                    Yes,{" "}
                    {Math.round(
                      (new Date(patient.deathDate).getTime() -
                        new Date(patient.procedureDate).getTime()) /
                        one_day
                    )}{" "}
                    days post procedure
                  </Typography>
                ) : (
                  <Typography className={"bold-weight"} variant="body2">
                    Yes, date not provided
                  </Typography>
                )}
              </div>
            )}
            <div className="column">
              {((adverseEventList && adverseEventList.length > 0) ||
                (performanceList && performanceList.length > 0) ||
                (clinicalBenefitList && clinicalBenefitList.length > 0) ||
                (deviceDeficientList && deviceDeficientList.length > 0)) && (
                <Typography className={"categorization-title"} variant="h6">
                  Categorization
                </Typography>
              )}
              {adverseEventList &&
                adverseEventList.length > 0 &&
                adverseEventList.map((adverseEvent) => {
                  return (
                    <div className="row" key={adverseEvent.id}>
                      <Chip
                        size="small"
                        // sx={{ bgcolor: red[50], color: red[800] }}
                        label={getAttributeLabel(
                          adverseEvent,
                          "Adverse Events"
                        )}
                        variant="body2"
                        icon={
                          <ReportProblemOutlined
                            fontSize="small"
                            // style={{ color: red[700] }}
                          />
                        }
                        onDelete={() => {}}
                        deleteIcon={
                          <Tooltip title={adverseEvent.reason}>
                            <StickyNote2Outlined
                              fontSize="small"
                              style={{ color: theme.palette.action.active }}
                            />
                          </Tooltip>
                        }
                      ></Chip>
                      <Link
                        component={"button"}
                        variant={"subtitle2"}
                        value="Adverse Events"
                        onClick={(e) => handleNewPopOver(e, adverseEvent)}
                      >
                        Edit
                      </Link>
                    </div>
                  );
                })}

              {clinicalBenefitList && clinicalBenefitList.length > 0 && (
                <div className="row">
                  <Chip
                    size="small"
                    // sx={{bgcolor: deepPurple[50], color: deepPurple[700]}}
                    label={getAttributeLabel(
                      clinicalBenefitList[0],
                      "Clinical Benefit"
                    )}
                    variant="body2"
                    icon={
                      <MedicalServicesOutlined
                        fontSize="small"
                        // style={{ color: deepPurple[600] }}
                      />
                    }
                    onDelete={() => {}}
                    deleteIcon={
                      <Tooltip title={clinicalBenefitList[0].reason}>
                        <StickyNote2Outlined
                          fontSize="small"
                          style={{ color: theme.palette.action.active }}
                        />
                      </Tooltip>
                    }
                  ></Chip>
                  <Link
                    component={"button"}
                    variant={"subtitle2"}
                    value="Clinical Benefit"
                    onClick={(e) => handleNewPopOver(e, clinicalBenefitList[0])}
                  >
                    Edit
                  </Link>
                </div>
              )}
              {performanceList && performanceList.length > 0 && (
                <div className="row">
                  <Chip
                    size="small"
                    // sx={{ bgcolor: cyan[50], color: cyan[700] }}
                    label={getAttributeLabel(performanceList[0], "Performance")}
                    variant="body2"
                    icon={
                      <TrendingUpOutlined
                        fontSize="small"
                        // style={{ color: cyan[600] }}
                      />
                    }
                    onDelete={() => {}}
                    deleteIcon={
                      <Tooltip title={performanceList[0].reason}>
                        <StickyNote2Outlined
                          fontSize="small"
                          style={{ color: theme.palette.action.active }}
                        />
                      </Tooltip>
                    }
                  ></Chip>
                  <Link
                    value={"Performance"}
                    component={"button"}
                    variant={"subtitle2"}
                    onClick={(e) => handleNewPopOver(e, performanceList[0])}
                  >
                    Edit
                  </Link>
                </div>
              )}
              {deviceDeficientList && deviceDeficientList.length > 0 && (
                <div className="row">
                  <Chip
                    size="small"
                    // sx={{bgcolor: deepPurple[50], color: deepPurple[700]}}
                    label={getAttributeLabel(
                      deviceDeficientList[0],
                      "Device Deficiency"
                    )}
                    variant="body2"
                    icon={
                      <DeviceHubOutlined
                        fontSize="small"
                        // style={{ color: deepPurple[600] }}
                      />
                    }
                    onDelete={() => {}}
                    deleteIcon={
                      <Tooltip title={deviceDeficientList[0].reason}>
                        <StickyNote2Outlined
                          fontSize="small"
                          style={{ color: theme.palette.action.active }}
                        />
                      </Tooltip>
                    }
                  ></Chip>
                  <Link
                    component={"button"}
                    variant={"subtitle2"}
                    value="Device Deficiency"
                    onClick={(e) => handleNewPopOver(e, deviceDeficientList[0])}
                  >
                    Edit
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Stack>
        <div className="column-for-structured-data">
          <Typography className={"patient-row-title"} variant="h6">
            Patient Data
          </Typography>
          <StructuredDataExpandTable
            loading={isLoading}
            title={"Problems"}
            count={patient?.problemsCount} // change to actual count from data
            handleChange={handleProblemsDataLoad}
            fields={problemDataFields}
            rows={problemsData}
          />
          {isPromEnabled && (
            <PromDataExpandTable
              loading={isPromDataLoading}
              title={"Patient Reported Outcomes"}
              count={patient.promCount}
              handleChange={handlePromLoad}
              promData={promData}
            />
          )}
        </div>
      </CardContent>
      {isOpen && (
        <IMDRFCategorize
          anchorEl={mark}
          onClose={handlePopOverClose}
          open={true}
          patientId={patient.id}
          attribute={attributeToEdit}
          updatePatientList={updatePatientList}
          patientNumber={patient.patientNumber}
        />
      )}
    </Card>
  );
}
