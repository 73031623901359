import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Typography,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Popover,
  CircularProgress,
  Stack,
  Collapse,
  Divider,
} from "@mui/material";
import { projectsPrefix } from "../../../../services/ProjectsServices";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "../../../../services/hooks/useFetch";
import { formatPatient } from "../../../../models/patient";
import { KeyboardArrowRight } from "@mui/icons-material";
import IMDRFComponent from "./IMDRFComponent";

import {
  categoryData,
  getCategoryFromAttributeType,
  getCategoryFromAttributeTypeId,
  getImdrfEnabled,
} from "./CategorizeUtils";
import AutoCompleteInput from "../AutoCompleteInput";
import { CurrentPatient } from "../../ProjectDetails";

export default function IMDRFCategorize({
  anchorEl,
  open,
  onClose,
  patientId,
  patientNumber,
  attribute,
  updatePatientList,
}) {
  const [showImdrfSelect, setShowImdrfSelect] = useState(false);
  const [showImdrfSelectWindow, setShowImdrfSelectWindow] = useState(false);
  const [imdrfCode, setImdrfCode] = useState(attribute?.imdrfCode || null);
  const [reason, setReason] = useState(attribute?.reason || "");
  const [category, setCategory] = useState(null);
  const [attributeValue, setAttributeValue] = useState(
    attribute?.attributeValue || null
  );
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [deleting, setDeleting] = useState(false);
  const [adverseSubcatsLoaded, setAdverseSubcatsLoaded] = useState(false);
  const [updatedPatient, setUpdatedPatient] = useState(null);
  const [deleteReason, setDeleteReason] = useState("");
  const actionRef = React.useRef();

  const { projectSettings } = useContext(CurrentPatient);

  const {
    create: createCategorization,
    update: updateCategorization,
    delete: deleteCategorization,
    isLoading: patientLoading,
    error: createError,
  } = useFetch();

  const {
    fetch: fetchAdverseSubCategories,
    isLoading: adverseSubCategoriesLoading,
  } = useFetch();

  const imdrfEnabled = getImdrfEnabled(projectSettings);

  function setSelectedCode(code) {
    setImdrfCode(code);
    setShowImdrfSelectWindow(false);
  }

  const handleClose = useCallback(() => {
    setLoading(false);
    setCategory(null);
    setImdrfCode(null);
    setAttributeValue(null);
    setReason("");
    setShowImdrfSelect(false);
    setUpdatedPatient(null);
    onClose();
  }, [onClose]);

  function handleCategoryChange(event) {
    const attributeTypeId = event.target.value;
    const category = getCategoryFromAttributeTypeId(attributeTypeId);
    setCategory(category);
    setAttributeValue(null);
    //For AEs we always show IMDRF selection
    //For the boolean types, we only show IMDRF selection when:
    //Performance or Clinical Benefit fail (false) or Device Deficient (true)
    //This is handled in handleChangeSub below
    handleShowImdrfSelect(category, null);
    setShowImdrfSelectWindow(false);
  }

  function handleChangeSub(event) {
    const attributeValue = event.target.value;
    setAttributeValue(attributeValue);

    if (
      (category.name === "Performance" && attributeValue) ||
      (category.name === "Device Deficiency" && !attributeValue)
    ) {
      setImdrfCode(null);
      if (attribute) attribute.imdrfCode = null;
      setShowImdrfSelectWindow(false);
    }
    handleShowImdrfSelect(category, attributeValue);
  }

  function handleShowImdrfSelect(category, attributeValue) {
    let showSelect = false;
    if (imdrfEnabled && category) {
      if (category.name === "Adverse Event") {
        showSelect = true;
      }
      if (category.name === "Performance") {
        showSelect = attributeValue === false;
      }
      if (category.name === "Device Deficiency") {
        showSelect = attributeValue === true;
      }
    }
    setShowImdrfSelect(showSelect);
  }

  function handleBody() {
    let code = imdrfCode;
    let selectedCode = undefined;
    if (
      imdrfCode &&
      !imdrfCode.isSelectable &&
      imdrfCode.isOverridden &&
      imdrfCode.overrideCode
    ) {
      code = imdrfCode.overrideCode;
      selectedCode = imdrfCode;
    }
    if (code && code.children) {
      code.children = null;
    }
    if (selectedCode && selectedCode.children) {
      selectedCode.children = null;
    }
    return {
      id: attribute?.id,
      attributeValue: attributeValue,
      attributeTypeId: category.attributeTypeId,
      imdrfCode: code,
      selectedImdrfCode: selectedCode,
      reason: reason,
    };
  }

  useEffect(() => {
    if (!adverseSubCategoriesLoading && !adverseSubcatsLoaded) {
      let aeCat = getCategoryFromAttributeType("Adverse Event");
      fetchAdverseSubCategories(
        `${projectsPrefix}/${projectId}/attributes/types/${aeCat.attributeTypeId}/values?search=`,
        (data) => {
          aeCat.subcategoryOptions = data;
          setAdverseSubcatsLoaded(true);
        }
      );
    }
  }, [
    adverseSubCategoriesLoading,
    adverseSubcatsLoaded,
    fetchAdverseSubCategories,
    projectId,
  ]);

  useEffect(() => {
    if (!patientLoading && updatedPatient) {
      setLoading(false);
      updatePatientList(formatPatient(updatedPatient));
      handleClose();
    }
  }, [patientLoading, updatedPatient, handleClose, updatePatientList]);

  useEffect(() => {
    if (createError) {
      //TODO: Handle these appropriately
      console.log("Error: " + createError.message);
      handleClose();
    }
  }, [createError, handleClose]);

  //This is to load an attribute for editing if one is passed in
  useEffect(() => {
    if (attribute && attribute.attributeTypeId) {
      let category = getCategoryFromAttributeTypeId(attribute.attributeTypeId);
      setCategory(category);

      let showImdrfSelect = false;
      if (attribute.imdrfCode != null) {
        showImdrfSelect = true;
      }
      setShowImdrfSelect(showImdrfSelect);
    }
  }, [attribute]);

  function onSubmit(event) {
    event.preventDefault();
    setLoading(true);

    const attributeData = handleBody();
    if (attributeData.reason.trim() === "") {
      setLoading(false);
      return;
    }
    submitAndClose(attributeData);
  }

  function submitAndClose(attributeData) {
    if (!attribute || !attribute?.id) {
      createCategorization(
        `${projectsPrefix}/${projectId}/patients/${patientId}/attributes`,
        attributeData,
        "POST",
        (data) => {
          setUpdatedPatient(data);
        }
      );
    } else {
      //patientId is required for updates, but not allowed for create in the API
      attributeData.patientId = patientId;
      updateCategorization(
        `${projectsPrefix}/${projectId}/patients/${patientId}/attributes/${attribute.id}`,
        attributeData,
        "PUT",
        (data) => {
          setUpdatedPatient(data);
        }
      );
    }
  }

  function onDelete(event) {
    event.preventDefault();
    setLoading(true);
    deleteAndClose();
  }

  function deleteAndClose() {
    deleteCategorization(
      `${projectsPrefix}/${projectId}/patients/${patientId}/attributes/${attribute.id}`,
      deleteReason,
      "DELETE",
      (data) => {
        setUpdatedPatient(data);
      }
    );
  }

  const getSubmitDisabled = useCallback(() => {
    if (loading) return true;
    if (!category) return true;
    if (attributeValue === null) return true;
    if (!reason || reason.trim() === "") return true;
    if (showImdrfSelect && !imdrfCode) return true;

    return false;
  }, [loading, category, attributeValue, reason, showImdrfSelect, imdrfCode]);

  const getDeleteDisabled = useCallback(() => {
    return deleteReason.trim() === "" || loading;
  }, [deleteReason, loading]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ maxHeight: "80%" }}
      action={actionRef}
    >
      <Typography variant="h6" sx={{ marginTop: "10px", marginLeft: "16px" }}>
        {`Categorize Patient ${patientNumber} ${
          category ? `/ ${category?.name}` : ""
        }`}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack direction="column" spacing={1.5} className="categorize-modal">
          <FormControl size="small" fullWidth>
            <InputLabel>Category Type</InputLabel>
            <Select
              label="Category Type"
              value={category?.attributeTypeId || ""}
              onChange={handleCategoryChange}
              disabled={!!attribute || deleting}
              variant="outlined"
              SelectDisplayProps={{
                style: { display: "flex", alignItems: "center", gap: "8px" },
              }}
            >
              {categoryData.map((c) => {
                return (
                  c.enabled && (
                    <MenuItem value={c.attributeTypeId} key={c.attributeTypeId}>
                      {c.icon}
                      {c.name}
                    </MenuItem>
                  )
                );
              })}
            </Select>
          </FormControl>
          {category && category.name === "Adverse Event" && (
            <AutoCompleteInput
              label="Sub-Category"
              state={attributeValue}
              setState={setAttributeValue}
              options={category?.subcategoryOptions ?? []}
              deleting={deleting}
            />
          )}
          {category &&
            category.subcategoryOptions &&
            category.name !== "Adverse Event" && (
              <FormControl fullWidth size="small">
                <InputLabel>Sub-Category</InputLabel>
                <Select
                  value={attributeValue === null ? "" : attributeValue}
                  size="small"
                  label="Sub-Category"
                  onChange={handleChangeSub}
                  disabled={deleting}
                  variant="outlined"
                >
                  {category.subcategoryOptions.map((subcat) => {
                    return (
                      <MenuItem
                        value={subcat === "Yes" || subcat === "Success"}
                        key={subcat}
                      >
                        {subcat}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

          {/*{attributeType === "Device Deficiency" && (*/}
          {/*  <FormControl size="small" fullWidth>*/}
          {/*    <InputLabel required>Device Deficient?</InputLabel>*/}
          {/*    <Select*/}
          {/*      label="Device Deficient?"*/}
          {/*      // value={deviceDeficient}*/}
          {/*      // onChange={handleChangeDeviceDeficient}*/}
          {/*      disabled={!!attribute || deleting}*/}
          {/*      required*/}
          {/*      variant="outlined"*/}
          {/*    >*/}
          {/*      <MenuItem value={"Yes"} key={"yes"}>*/}
          {/*        Yes*/}
          {/*      </MenuItem>*/}
          {/*      <MenuItem value={"No"} key={"no"}>*/}
          {/*        No*/}
          {/*      </MenuItem>*/}
          {/*    </Select>*/}
          {/*  </FormControl>*/}
          {/*)}*/}
          {/*<TextField*/}
          {/*  className="categorize-modal-textfield"*/}
          {/*  label="Days Post Procedure"*/}
          {/*  placeholder={"0-n"}*/}
          {/*  type="number"*/}
          {/*  InputProps={{ inputProps: { min: 0 } }}*/}
          {/*  size="small"*/}
          {/*  fullWidth*/}
          {/*  required*/}
          {/*  value={daysPostProcedure}*/}
          {/*  onChange={(event) => setDaysPostProcedure(event.target.value)}*/}
          {/*  disabled={deleting}*/}
          {/*/>*/}

          {showImdrfSelect && imdrfEnabled && (
            <Stack direction="row" width={"100%"} alignItems={"center"}>
              <TextField
                className="categorize-modal-textfield"
                label="IMDRF Code"
                value={
                  imdrfCode
                    ? `${imdrfCode.code} - ${imdrfCode.term}`
                    : "No IMDRF Code Selected"
                }
                fullWidth
                disabled
                required
                variant={"standard"}
                InputProps={{ disableUnderline: true }}
                sx={{
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                  paddingRight: "2px",
                }}
              />
              <Button
                onClick={() => setShowImdrfSelectWindow(true)}
                variant="contained"
                size="small"
                fullWidth
                endIcon={<KeyboardArrowRight size="1.5rem" />}
                disabled={!category || showImdrfSelectWindow}
              >
                {imdrfCode
                  ? "SEARCH FOR A DIFFERENT CODE"
                  : "SEARCH FOR IMDRF CODE"}
              </Button>
            </Stack>
          )}
          {/*<FormControl size="small" fullWidth>*/}
          {/*  <InputLabel required>Adverse Device Effect</InputLabel>*/}
          {/*  <Select*/}
          {/*    label="Adverse Device Effect"*/}
          {/*    value={adverseEffect}*/}
          {/*    onChange={(event) => setAdverseEffect(event.target.value)}*/}
          {/*    disabled={!!selected || deleting}*/}
          {/*    required*/}
          {/*    variant="outlined"*/}
          {/*  >*/}
          {/*    <MenuItem value={"Yes"} key={"yes"}>*/}
          {/*      Yes*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem value={"No"} key={"no"}>*/}
          {/*      No*/}
          {/*    </MenuItem>*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*<TextField*/}
          {/*  className="categorize-modal-textfield"*/}
          {/*  label="AE Treatment (optional)"*/}
          {/*  placeholder={"Treatment Name"}*/}
          {/*  size="small"*/}
          {/*  fullWidth*/}
          {/*  value={aeTreatment}*/}
          {/*  onChange={(event) => setAeTreatment(event.target.value)}*/}
          {/*  disabled={deleting}*/}
          {/*/>*/}
          {/*<FormControl size="small" fullWidth>*/}
          {/*  <InputLabel>Resolution (optional)</InputLabel>*/}
          {/*  <Select*/}
          {/*    label="Resolution (optional)"*/}
          {/*    value={resolution}*/}
          {/*    onChange={(event) => setResolution(event.target.value)}*/}
          {/*    disabled={!!selected || deleting}*/}
          {/*    variant="outlined"*/}
          {/*  >*/}
          {/*    <MenuItem value={"Yes"} key={"yes"}>*/}
          {/*      Yes*/}
          {/*    </MenuItem>*/}
          {/*    <MenuItem value={"No"} key={"no"}>*/}
          {/*      No*/}
          {/*    </MenuItem>*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*<TextField*/}
          {/*  className="categorize-modal-textfield"*/}
          {/*  label="Resolution Days Post Onset (optional)"*/}
          {/*  placeholder={"0-n"}*/}
          {/*  type="number"*/}
          {/*  InputProps={{ inputProps: { min: 0 } }}*/}
          {/*  size="small"*/}
          {/*  fullWidth*/}
          {/*  value={resolutionDaysPostOnset}*/}
          {/*  onChange={(event) => setResolutionDaysPostOnset(event.target.value)}*/}
          {/*  disabled={deleting}*/}
          {/*/>*/}

          <TextField
            className="categorize-modal-textfield"
            label="Notes/Reason"
            fullWidth
            required
            multiline
            rows={4}
            value={reason || ""}
            onChange={(event) => setReason(event.target.value)}
            disabled={deleting}
          />
          {deleting && (
            <TextField
              className="categorize-modal-delete-textfield"
              label="Enter Note/Reason for Deleting"
              fullWidth
              maxRows={4}
              multiline
              value={deleteReason || ""}
              onChange={(event) => setDeleteReason(event.target.value)}
              placeholder="Please enter a reason for deleting this categorization."
            />
          )}
          {!deleting && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {!!attribute && (
                <Button
                  onClick={() => setDeleting(true)}
                  color="error"
                  variant="contained"
                  size="small"
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={loading}
                >
                  Delete
                </Button>
              )}
              <Stack
                direction="row"
                spacing={1}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <Button onClick={handleClose} variant="outlined" size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => onSubmit(e)}
                  size="small"
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={getSubmitDisabled()}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          )}
          {deleting && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <Button
                onClick={() => setDeleting(false)}
                variant="outlined"
                size="small"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={(e) => onDelete(e)}
                size="small"
                endIcon={loading && <CircularProgress size="1.5rem" />}
                disabled={getDeleteDisabled()}
              >
                Delete
              </Button>
            </Stack>
          )}
        </Stack>
        <Collapse
          orientation="horizontal"
          in={showImdrfSelectWindow}
          unmountOnExit
          timeout={0}
          onEntered={() => actionRef.current.updatePosition()}
          onExited={() => actionRef.current.updatePosition()}
        >
          <IMDRFComponent
            projectId={projectId}
            annexes={category?.annexes}
            selectedCode={imdrfCode}
            setSelectedCode={setSelectedCode}
          />
        </Collapse>
      </Stack>
    </Popover>
  );
}
