import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import { DeviceHubOutlined, ReportProblemOutlined } from "@mui/icons-material";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import React from "react";

export function getImdrfEnabled(projectSettings) {
  const imdrfSetting = projectSettings?.find((s) => s.name === "DISABLE_IMDRF");
  return (
    !imdrfSetting || imdrfSetting.value === "" || imdrfSetting.value === "FALSE"
  );
}

export function getCategoryFromAttributeType(attributeType) {
  return categoryData.find((s) => s.name === attributeType);
}

export function getCategoryFromAttributeTypeId(attributeTypeId) {
  return categoryData.find((s) => s.attributeTypeId === attributeTypeId);
}

export const categoryData = [
  {
    name: "Clinical Benefit",
    subcategoryOptions: ["Success", "Failure"],
    icon: <MedicalServicesOutlinedIcon />,
    annexes: null,
    attributeTypeId: "7af609de-01cf-49c4-8d3f-52951266804e",
    enabled: true,
  },
  {
    name: "Device Deficiency",
    subcategoryOptions: ["Yes", "No"],
    annexes: [
      {
        id: "A",
        label: "Annex A: Medical Device Problem",
        children: [],
      },
    ],
    icon: <DeviceHubOutlined />,
    attributeTypeId: "6a313d36-9c45-4813-a001-fb0f14f62429",
    enabled: true,
  },
  {
    name: "Adverse Event",
    subcategoryOptions: null,
    annexes: [
      {
        id: "E",
        label:
          "Annex E: Health Effects - Clinical Signs and Symptoms or Conditions",
        children: [],
      },
      {
        id: "F",
        label: "Annex F: Health Effects - Health Impact",
        children: [],
      },
    ],
    icon: <ReportProblemOutlined />,
    attributeTypeId: "3dc7dff2-2f82-45f7-85fc-782b4b7f78db",
    enabled: true,
  },
  {
    name: "Performance",
    subcategoryOptions: ["Success", "Failure"],
    annexes: [
      {
        id: "A",
        label: "Annex A: Medical Device Problem",
        children: [],
      },
    ],
    icon: <TrendingUpOutlinedIcon />,
    attributeTypeId: "8d83844c-87d3-429f-a286-5b60525985a0",
    enabled: true,
  },
];
