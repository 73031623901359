import { compareTwoValues, convertDate } from "../utils/main";
import { getCategoryFromAttributeType } from "../components/details/patients/categorize/CategorizeUtils";

export const emptyEncounter = {
  arriveDate: "",
  admitDate: "",
  dischargeDate: "",
  diagnosisCodes: [],
  id: "",
  notes: [],
  procedureCodes: [],
  encounterType: "",
};

export const emptyPatient = {
  id: null,
  age: 0,
  sex: null,
  procedureDate: new Date("0001-01-01"),
  targetLocation: null,
  indicationForUse: null,
  deviceDeficient: null,
  procedureCodes: [],
  diagnosisCodes: [],
  encounters: [],
  patientSummary: {
    id: 0,
    age: 0,
    sex: "Undefined",
    procedureDate: new Date("0001-01-01"),
  },
  reasons: [],
};

export function formatPatient(rawPatient) {
  if (!rawPatient && !rawPatient.id) return null;

  const { stringToDate } = convertDate;
  rawPatient.procedureDate = stringToDate(rawPatient.procedureDate);
  rawPatient.patientSummary.procedureDate = stringToDate(
    rawPatient.patientSummary?.procedureDate
  );

  //TODO: Unravel the ridiculous patient.patientSummary mess
  let category = getCategoryFromAttributeType("Adverse Event");
  const aeList = rawPatient.attributes?.filter(
    (a) => a.attributeTypeId === category?.attributeTypeId
  );
  rawPatient.patientSummary.adverseEvents = aeList && aeList.length > 0;
  rawPatient.adverseEvents = aeList && aeList.length > 0;

  category = getCategoryFromAttributeType("Performance");
  const perfList = rawPatient.attributes?.filter(
    (a) => a.attributeTypeId === category?.attributeTypeId
  );
  rawPatient.patientSummary.performance = perfList && perfList.length > 0;
  rawPatient.performance = perfList && perfList.length > 0;

  category = getCategoryFromAttributeType("Clinical Benefit");
  const cbList = rawPatient.attributes?.filter(
    (a) => a.attributeTypeId === category?.attributeTypeId
  );
  rawPatient.patientSummary.clinicalBenefit = cbList && cbList.length > 0;
  rawPatient.clinicalBenefit = cbList && cbList.length > 0;

  category = getCategoryFromAttributeType("Device Deficiency");
  const ddList = rawPatient.attributes?.filter(
    (a) => a.attributeTypeId === category?.attributeTypeId
  );
  rawPatient.patientSummary.deviceDeficient = ddList && ddList.length > 0;
  rawPatient.deviceDeficient = ddList && ddList.length > 0;

  return rawPatient;
}

export function formatEncounter(encounter) {
  const { stringToDate } = convertDate;
  const arriveDate = stringToDate(encounter.arriveDate);
  return { ...encounter, arriveDate };
}

export function patientActivityLogs(logs) {
  const { stringToDate } = convertDate;
  return logs.map((log) => {
    return {
      ...log,
      createdAt: stringToDate(log.createdAt),
    };
  });
}

// TO-DO: this logic doesn't work assuming newVisitData is an array of visits versus a single visit... Update its definition or usage
export function updatePatientVisits(visits, newVisitData) {
  return visits.reduce((prev, curr) => {
    if (curr.id === newVisitData.id) {
      return prev.concat({ ...curr, ...newVisitData });
    }
    return prev.concat(curr);
  }, []);
}

export const visitsSortByEnum = {
  title: "diffDays",
  priority: "priority",
  related: "isRelated",
};

export function sortPatientVisits(sort, sortBy, visits) {
  if (sortBy === visitsSortByEnum.related) {
    return visits.sort((a) => {
      if (sort === "asc") {
        return a.isRelated || a.isRelated === null ? -1 : 1;
      }
      return a.isRelated || a.isRelated === null ? 1 : -1;
    });
  }
  return visits.sort((a, b) => {
    if (sort === "asc") {
      return compareTwoValues(a[sortBy], b[sortBy]);
    }
    return compareTwoValues(b[sortBy], a[sortBy]);
  });
}
export function sortEncounterCodes(sort, sortBy, codes) {
  const dataSortedAsc = codes.sort((a, b) => {
    return compareTwoValues(a[sortBy], b[sortBy]);
  });
  if (sort === "asc") {
    return dataSortedAsc;
  }
  return dataSortedAsc.reverse();
}
